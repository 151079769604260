import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Plus = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { toolID } = parsedLocation;
  useRedirectHandler(() => {
    if (toolID) {
      return "https://www.classdojo.com/toolkit/";
    } else {
      return "https://www.classdojo.com/plus/";
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Plus;
